import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { Row, Col, Gap, Text, Section, SEO, Post } from '../components'
import { extractNodesFromData } from '../lib/helpers'

class PostTemplate extends React.Component {
  render() {
    const {
      location: { pathname },
      data,
      pageContext,
      ...rest
    } = this.props
    const { posts, contentImages } = extractNodesFromData(data)

    const selectedPost = posts.find(({ slug }) => slug === pageContext.slug)
    const otherPosts = posts.filter(({ slug, state }) => slug !== pageContext.slug && state === 'published')

    if (!selectedPost) return null
    return (
      <>
        <SEO
          title={`${selectedPost.metaTitle || selectedPost.title} | Zuri`}
          description={
            selectedPost.metaDescription
            || 'We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart.'
          }
          pathname={pathname}
        />

        <Gap.Top />
        <Section background="black">
          <Post post={selectedPost} contentImages={contentImages} isSinglePost />
          <Post.MorePosts posts={otherPosts} />
        </Section>
      </>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query {
    contentImages: allFile(filter: { relativePath: { regex: "/^uploaded-image-/" } }) {
      edges {
        node {
          ...PostContentImage
          relativePath
        }
      }
    }

    posts: allPosts(
      sort: { fields: createdAt, order: DESC }
      filter: { state: { in: ["published", "unlisted"] } }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
`
